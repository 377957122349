/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Workspace Warden
 * OpenAPI spec version: 0.0.1
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  AddAuthorizedUserDto,
  AddMemberDto,
  AuthControllerLoginParams,
  ChecklistControllerFindAllParams,
  ChecklistControllerUploadCsvBody,
  ChecklistDto,
  ChecklistMembersDto,
  ChecklistProductsDto,
  ChecklistSearchResultDto,
  ConnectChecklistProductsDto,
  CreateChecklistDto,
  CreateSubscriptionTransactionDto,
  DeleteChecklistMembersDto,
  DeleteChecklistProductsDto,
  GetAuthorizedUsersDto,
  GetBillingInfoDto,
  GetPaymentStatusDto,
  GlobalSearchResultDto,
  ImpersonatorDto,
  LoginInfoDto,
  OrgUnitsDto,
  ProductDetailsDto,
  ProductSearchResultDto,
  RemoveAuthorizedUserDto,
  RemoveMemberDto,
  SearchControllerGetAllProductsParams,
  SearchControllerSearchAllParams,
  SearchControllerSearchChecklistsParams,
  SearchControllerSearchProductsParams,
  SearchControllerSearchUsersParams,
  SetBillingInfoDto,
  SubscriptionInfoDto,
  SyncInfoDto,
  TenantSubscriptionStatusDto,
  UpdateChecklistDto,
  UpdateChecklistMembersDto,
  UpdateMemberRoleDto,
  UserSearchResultDto,
} from '../models';

export const checklistControllerCreate = (
  createChecklistDto: CreateChecklistDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistDto>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/checklist`,
    createChecklistDto,
    options,
  );
};

export const getChecklistControllerCreateMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerCreate>>,
    TError,
    { data: CreateChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerCreate>>,
  TError,
  { data: CreateChecklistDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerCreate>>,
    { data: CreateChecklistDto }
  > = (props) => {
    const { data } = props ?? {};

    return checklistControllerCreate(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerCreate>>
>;
export type ChecklistControllerCreateMutationBody = CreateChecklistDto;
export type ChecklistControllerCreateMutationError = AxiosError<unknown>;

export const useChecklistControllerCreate = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerCreate>>,
    TError,
    { data: CreateChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerCreate>>,
  TError,
  { data: CreateChecklistDto },
  TContext
> => {
  const mutationOptions = getChecklistControllerCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerFindAll = (
  params?: ChecklistControllerFindAllParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistDto[]>> => {
  return axios.get(`https://staging.workspacewarden.com/api/checklist`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getChecklistControllerFindAllQueryKey = (
  params?: ChecklistControllerFindAllParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/checklist`,
    ...(params ? [params] : []),
  ] as const;
};

export const getChecklistControllerFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof checklistControllerFindAll>>,
  TError = AxiosError<void>,
>(
  params?: ChecklistControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerFindAll>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChecklistControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checklistControllerFindAll>>
  > = ({ signal }) =>
    checklistControllerFindAll(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checklistControllerFindAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChecklistControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerFindAll>>
>;
export type ChecklistControllerFindAllQueryError = AxiosError<void>;

export const useChecklistControllerFindAll = <
  TData = Awaited<ReturnType<typeof checklistControllerFindAll>>,
  TError = AxiosError<void>,
>(
  params?: ChecklistControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerFindAll>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChecklistControllerFindAllQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const checklistControllerGetChecklist = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/checklist/${id}`,
    options,
  );
};

export const getChecklistControllerGetChecklistQueryKey = (id: string) => {
  return [`https://staging.workspacewarden.com/api/checklist/${id}`] as const;
};

export const getChecklistControllerGetChecklistQueryOptions = <
  TData = Awaited<ReturnType<typeof checklistControllerGetChecklist>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerGetChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChecklistControllerGetChecklistQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checklistControllerGetChecklist>>
  > = ({ signal }) =>
    checklistControllerGetChecklist(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof checklistControllerGetChecklist>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChecklistControllerGetChecklistQueryResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerGetChecklist>>
>;
export type ChecklistControllerGetChecklistQueryError = AxiosError<unknown>;

export const useChecklistControllerGetChecklist = <
  TData = Awaited<ReturnType<typeof checklistControllerGetChecklist>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerGetChecklist>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChecklistControllerGetChecklistQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const checklistControllerRename = (
  id: string,
  updateChecklistDto: UpdateChecklistDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `https://staging.workspacewarden.com/api/checklist/${id}`,
    updateChecklistDto,
    options,
  );
};

export const getChecklistControllerRenameMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerRename>>,
    TError,
    { id: string; data: UpdateChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerRename>>,
  TError,
  { id: string; data: UpdateChecklistDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerRename>>,
    { id: string; data: UpdateChecklistDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return checklistControllerRename(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerRenameMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerRename>>
>;
export type ChecklistControllerRenameMutationBody = UpdateChecklistDto;
export type ChecklistControllerRenameMutationError = AxiosError<unknown>;

export const useChecklistControllerRename = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerRename>>,
    TError,
    { id: string; data: UpdateChecklistDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerRename>>,
  TError,
  { id: string; data: UpdateChecklistDto },
  TContext
> => {
  const mutationOptions = getChecklistControllerRenameMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerRemove = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/checklist/${id}`,
    options,
  );
};

export const getChecklistControllerRemoveMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerRemove>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerRemove>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return checklistControllerRemove(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerRemove>>
>;

export type ChecklistControllerRemoveMutationError = AxiosError<unknown>;

export const useChecklistControllerRemove = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerRemove>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getChecklistControllerRemoveMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerConnectProduct = (
  id: string,
  connectChecklistProductsDto: ConnectChecklistProductsDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistProductsDto>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/checklist/${id}/products`,
    connectChecklistProductsDto,
    options,
  );
};

export const getChecklistControllerConnectProductMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerConnectProduct>>,
    TError,
    { id: string; data: ConnectChecklistProductsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerConnectProduct>>,
  TError,
  { id: string; data: ConnectChecklistProductsDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerConnectProduct>>,
    { id: string; data: ConnectChecklistProductsDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return checklistControllerConnectProduct(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerConnectProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerConnectProduct>>
>;
export type ChecklistControllerConnectProductMutationBody =
  ConnectChecklistProductsDto;
export type ChecklistControllerConnectProductMutationError =
  AxiosError<unknown>;

export const useChecklistControllerConnectProduct = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerConnectProduct>>,
    TError,
    { id: string; data: ConnectChecklistProductsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerConnectProduct>>,
  TError,
  { id: string; data: ConnectChecklistProductsDto },
  TContext
> => {
  const mutationOptions =
    getChecklistControllerConnectProductMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerDeleteConnction = (
  id: string,
  deleteChecklistProductsDto: DeleteChecklistProductsDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/checklist/${id}/products`,
    { data: deleteChecklistProductsDto, ...options },
  );
};

export const getChecklistControllerDeleteConnctionMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerDeleteConnction>>,
    TError,
    { id: string; data: DeleteChecklistProductsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerDeleteConnction>>,
  TError,
  { id: string; data: DeleteChecklistProductsDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerDeleteConnction>>,
    { id: string; data: DeleteChecklistProductsDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return checklistControllerDeleteConnction(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerDeleteConnctionMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerDeleteConnction>>
>;
export type ChecklistControllerDeleteConnctionMutationBody =
  DeleteChecklistProductsDto;
export type ChecklistControllerDeleteConnctionMutationError =
  AxiosError<unknown>;

export const useChecklistControllerDeleteConnction = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerDeleteConnction>>,
    TError,
    { id: string; data: DeleteChecklistProductsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerDeleteConnction>>,
  TError,
  { id: string; data: DeleteChecklistProductsDto },
  TContext
> => {
  const mutationOptions =
    getChecklistControllerDeleteConnctionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerGetMembers = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistMembersDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/checklist/${id}/members`,
    options,
  );
};

export const getChecklistControllerGetMembersQueryKey = (id: string) => {
  return [
    `https://staging.workspacewarden.com/api/checklist/${id}/members`,
  ] as const;
};

export const getChecklistControllerGetMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof checklistControllerGetMembers>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerGetMembers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getChecklistControllerGetMembersQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checklistControllerGetMembers>>
  > = ({ signal }) =>
    checklistControllerGetMembers(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof checklistControllerGetMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ChecklistControllerGetMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerGetMembers>>
>;
export type ChecklistControllerGetMembersQueryError = AxiosError<unknown>;

export const useChecklistControllerGetMembers = <
  TData = Awaited<ReturnType<typeof checklistControllerGetMembers>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof checklistControllerGetMembers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getChecklistControllerGetMembersQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const checklistControllerAddMembers = (
  id: string,
  updateChecklistMembersDto: UpdateChecklistMembersDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistMembersDto>> => {
  return axios.patch(
    `https://staging.workspacewarden.com/api/checklist/${id}/members`,
    updateChecklistMembersDto,
    options,
  );
};

export const getChecklistControllerAddMembersMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerAddMembers>>,
    TError,
    { id: string; data: UpdateChecklistMembersDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerAddMembers>>,
  TError,
  { id: string; data: UpdateChecklistMembersDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerAddMembers>>,
    { id: string; data: UpdateChecklistMembersDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return checklistControllerAddMembers(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerAddMembersMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerAddMembers>>
>;
export type ChecklistControllerAddMembersMutationBody =
  UpdateChecklistMembersDto;
export type ChecklistControllerAddMembersMutationError = AxiosError<unknown>;

export const useChecklistControllerAddMembers = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerAddMembers>>,
    TError,
    { id: string; data: UpdateChecklistMembersDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerAddMembers>>,
  TError,
  { id: string; data: UpdateChecklistMembersDto },
  TContext
> => {
  const mutationOptions =
    getChecklistControllerAddMembersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerDeleteMembers = (
  id: string,
  deleteChecklistMembersDto: DeleteChecklistMembersDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/checklist/${id}/members`,
    { data: deleteChecklistMembersDto, ...options },
  );
};

export const getChecklistControllerDeleteMembersMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerDeleteMembers>>,
    TError,
    { id: string; data: DeleteChecklistMembersDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerDeleteMembers>>,
  TError,
  { id: string; data: DeleteChecklistMembersDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerDeleteMembers>>,
    { id: string; data: DeleteChecklistMembersDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return checklistControllerDeleteMembers(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerDeleteMembersMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerDeleteMembers>>
>;
export type ChecklistControllerDeleteMembersMutationBody =
  DeleteChecklistMembersDto;
export type ChecklistControllerDeleteMembersMutationError = AxiosError<unknown>;

export const useChecklistControllerDeleteMembers = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerDeleteMembers>>,
    TError,
    { id: string; data: DeleteChecklistMembersDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerDeleteMembers>>,
  TError,
  { id: string; data: DeleteChecklistMembersDto },
  TContext
> => {
  const mutationOptions =
    getChecklistControllerDeleteMembersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checklistControllerUploadCsv = (
  checklistControllerUploadCsvBody: ChecklistControllerUploadCsvBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistDto>> => {
  const formData = new FormData();
  formData.append('name', checklistControllerUploadCsvBody.name);
  formData.append('file', checklistControllerUploadCsvBody.file);

  return axios.post(
    `https://staging.workspacewarden.com/api/checklist/upload`,
    formData,
    options,
  );
};

export const getChecklistControllerUploadCsvMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerUploadCsv>>,
    TError,
    { data: ChecklistControllerUploadCsvBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof checklistControllerUploadCsv>>,
  TError,
  { data: ChecklistControllerUploadCsvBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checklistControllerUploadCsv>>,
    { data: ChecklistControllerUploadCsvBody }
  > = (props) => {
    const { data } = props ?? {};

    return checklistControllerUploadCsv(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChecklistControllerUploadCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof checklistControllerUploadCsv>>
>;
export type ChecklistControllerUploadCsvMutationBody =
  ChecklistControllerUploadCsvBody;
export type ChecklistControllerUploadCsvMutationError = AxiosError<unknown>;

export const useChecklistControllerUploadCsv = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checklistControllerUploadCsv>>,
    TError,
    { data: ChecklistControllerUploadCsvBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof checklistControllerUploadCsv>>,
  TError,
  { data: ChecklistControllerUploadCsvBody },
  TContext
> => {
  const mutationOptions =
    getChecklistControllerUploadCsvMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionControllerCreateSubscription = (
  subscriptionInfoDto: SubscriptionInfoDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<CreateSubscriptionTransactionDto>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/subscriptions/create`,
    subscriptionInfoDto,
    options,
  );
};

export const getSubscriptionControllerCreateSubscriptionMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>,
    TError,
    { data: SubscriptionInfoDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>,
  TError,
  { data: SubscriptionInfoDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>,
    { data: SubscriptionInfoDto }
  > = (props) => {
    const { data } = props ?? {};

    return subscriptionControllerCreateSubscription(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscriptionControllerCreateSubscriptionMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>
  >;
export type SubscriptionControllerCreateSubscriptionMutationBody =
  SubscriptionInfoDto;
export type SubscriptionControllerCreateSubscriptionMutationError =
  AxiosError<unknown>;

export const useSubscriptionControllerCreateSubscription = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>,
    TError,
    { data: SubscriptionInfoDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscriptionControllerCreateSubscription>>,
  TError,
  { data: SubscriptionInfoDto },
  TContext
> => {
  const mutationOptions =
    getSubscriptionControllerCreateSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const subscriptionControllerGetMyCustomer = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TenantSubscriptionStatusDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/subscriptions/status`,
    options,
  );
};

export const getSubscriptionControllerGetMyCustomerQueryKey = () => {
  return [
    `https://staging.workspacewarden.com/api/subscriptions/status`,
  ] as const;
};

export const getSubscriptionControllerGetMyCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>,
  TError = AxiosError<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSubscriptionControllerGetMyCustomerQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>
  > = ({ signal }) =>
    subscriptionControllerGetMyCustomer({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SubscriptionControllerGetMyCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>
>;
export type SubscriptionControllerGetMyCustomerQueryError = AxiosError<void>;

export const useSubscriptionControllerGetMyCustomer = <
  TData = Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>,
  TError = AxiosError<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof subscriptionControllerGetMyCustomer>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSubscriptionControllerGetMyCustomerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const subscriptionControllerUpdateRenewal = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `https://staging.workspacewarden.com/api/subscriptions/renew`,
    undefined,
    options,
  );
};

export const getSubscriptionControllerUpdateRenewalMutationOptions = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>,
    void
  > = () => {
    return subscriptionControllerUpdateRenewal(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubscriptionControllerUpdateRenewalMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>
>;

export type SubscriptionControllerUpdateRenewalMutationError = AxiosError<void>;

export const useSubscriptionControllerUpdateRenewal = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof subscriptionControllerUpdateRenewal>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getSubscriptionControllerUpdateRenewalMutationOptions(options);

  return useMutation(mutationOptions);
};

export const paymentControllerGetPaymentStatus = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPaymentStatusDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/payments/order/${id}`,
    options,
  );
};

export const getPaymentControllerGetPaymentStatusQueryKey = (id: string) => {
  return [
    `https://staging.workspacewarden.com/api/payments/order/${id}`,
  ] as const;
};

export const getPaymentControllerGetPaymentStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>,
  TError = AxiosError<void>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPaymentControllerGetPaymentStatusQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>
  > = ({ signal }) =>
    paymentControllerGetPaymentStatus(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PaymentControllerGetPaymentStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>
>;
export type PaymentControllerGetPaymentStatusQueryError = AxiosError<void>;

export const usePaymentControllerGetPaymentStatus = <
  TData = Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>,
  TError = AxiosError<void>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof paymentControllerGetPaymentStatus>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPaymentControllerGetPaymentStatusQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const paymentControllerHandlePaymentStatusNotification = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/payments/order/notify`,
    undefined,
    options,
  );
};

export const getPaymentControllerHandlePaymentStatusNotificationMutationOptions =
  <TError = AxiosError<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof paymentControllerHandlePaymentStatusNotification>
      >,
      TError,
      void,
      TContext
    >;
    axios?: AxiosRequestConfig;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof paymentControllerHandlePaymentStatusNotification>
    >,
    TError,
    void,
    TContext
  > => {
    const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof paymentControllerHandlePaymentStatusNotification>
      >,
      void
    > = () => {
      return paymentControllerHandlePaymentStatusNotification(axiosOptions);
    };

    return { mutationFn, ...mutationOptions };
  };

export type PaymentControllerHandlePaymentStatusNotificationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof paymentControllerHandlePaymentStatusNotification>>
  >;

export type PaymentControllerHandlePaymentStatusNotificationMutationError =
  AxiosError<unknown>;

export const usePaymentControllerHandlePaymentStatusNotification = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof paymentControllerHandlePaymentStatusNotification>
    >,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof paymentControllerHandlePaymentStatusNotification>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getPaymentControllerHandlePaymentStatusNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const searchControllerGetAllProducts = (
  params?: SearchControllerGetAllProductsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProductSearchResultDto[]>> => {
  return axios.get(`https://staging.workspacewarden.com/api/search/products`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getSearchControllerGetAllProductsQueryKey = (
  params?: SearchControllerGetAllProductsParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/search/products`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchControllerGetAllProductsInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    SearchControllerGetAllProductsParams['page']
  >,
  TError = AxiosError<unknown>,
>(
  params?: SearchControllerGetAllProductsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        QueryKey,
        SearchControllerGetAllProductsParams['page']
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchControllerGetAllProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    QueryKey,
    SearchControllerGetAllProductsParams['page']
  > = ({ pageParam }) =>
    searchControllerGetAllProducts(
      { ...params, page: pageParam || params?.['page'] },
      axiosOptions,
    );

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    TError,
    TData,
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    QueryKey,
    SearchControllerGetAllProductsParams['page']
  > & { queryKey: QueryKey };
};

export type SearchControllerGetAllProductsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerGetAllProducts>>
>;
export type SearchControllerGetAllProductsInfiniteQueryError =
  AxiosError<unknown>;

export const useSearchControllerGetAllProductsInfinite = <
  TData = InfiniteData<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    SearchControllerGetAllProductsParams['page']
  >,
  TError = AxiosError<unknown>,
>(
  params?: SearchControllerGetAllProductsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        TError,
        TData,
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        QueryKey,
        SearchControllerGetAllProductsParams['page']
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerGetAllProductsInfiniteQueryOptions(
    params,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSearchControllerGetAllProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
  TError = AxiosError<unknown>,
>(
  params?: SearchControllerGetAllProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchControllerGetAllProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>
  > = () => searchControllerGetAllProducts(params, axiosOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchControllerGetAllProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerGetAllProducts>>
>;
export type SearchControllerGetAllProductsQueryError = AxiosError<unknown>;

export const useSearchControllerGetAllProducts = <
  TData = Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
  TError = AxiosError<unknown>,
>(
  params?: SearchControllerGetAllProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerGetAllProducts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerGetAllProductsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const searchControllerSearchProducts = (
  query: string,
  params?: SearchControllerSearchProductsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProductSearchResultDto[]>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/search/products/${query}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getSearchControllerSearchProductsQueryKey = (
  query: string,
  params?: SearchControllerSearchProductsParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/search/products/${query}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchControllerSearchProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchControllerSearchProducts>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchProducts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchControllerSearchProductsQueryKey(query, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerSearchProducts>>
  > = ({ signal }) =>
    searchControllerSearchProducts(query, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!query,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchControllerSearchProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchControllerSearchProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerSearchProducts>>
>;
export type SearchControllerSearchProductsQueryError = AxiosError<unknown>;

export const useSearchControllerSearchProducts = <
  TData = Awaited<ReturnType<typeof searchControllerSearchProducts>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchProducts>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerSearchProductsQueryOptions(
    query,
    params,
    options,
  );

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const searchControllerSearchUsers = (
  query: string,
  params?: SearchControllerSearchUsersParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserSearchResultDto[]>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/search/users/${query}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getSearchControllerSearchUsersQueryKey = (
  query: string,
  params?: SearchControllerSearchUsersParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/search/users/${query}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchControllerSearchUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchControllerSearchUsers>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchControllerSearchUsersQueryKey(query, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerSearchUsers>>
  > = ({ signal }) =>
    searchControllerSearchUsers(query, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!query,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchControllerSearchUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchControllerSearchUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerSearchUsers>>
>;
export type SearchControllerSearchUsersQueryError = AxiosError<unknown>;

export const useSearchControllerSearchUsers = <
  TData = Awaited<ReturnType<typeof searchControllerSearchUsers>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchUsersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchUsers>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerSearchUsersQueryOptions(
    query,
    params,
    options,
  );

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const searchControllerSearchChecklists = (
  query: string,
  params?: SearchControllerSearchChecklistsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ChecklistSearchResultDto[]>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/search/checklists/${query}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getSearchControllerSearchChecklistsQueryKey = (
  query: string,
  params?: SearchControllerSearchChecklistsParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/search/checklists/${query}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchControllerSearchChecklistsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchControllerSearchChecklists>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchChecklistsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchChecklists>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchControllerSearchChecklistsQueryKey(query, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerSearchChecklists>>
  > = ({ signal }) =>
    searchControllerSearchChecklists(query, params, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!query,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchControllerSearchChecklists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchControllerSearchChecklistsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerSearchChecklists>>
>;
export type SearchControllerSearchChecklistsQueryError = AxiosError<unknown>;

export const useSearchControllerSearchChecklists = <
  TData = Awaited<ReturnType<typeof searchControllerSearchChecklists>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchChecklistsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchChecklists>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerSearchChecklistsQueryOptions(
    query,
    params,
    options,
  );

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const searchControllerSearchAll = (
  query: string,
  params?: SearchControllerSearchAllParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GlobalSearchResultDto[]>> => {
  return axios.get(`https://staging.workspacewarden.com/api/search/${query}`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getSearchControllerSearchAllQueryKey = (
  query: string,
  params?: SearchControllerSearchAllParams,
) => {
  return [
    `https://staging.workspacewarden.com/api/search/${query}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchControllerSearchAllQueryOptions = <
  TData = Awaited<ReturnType<typeof searchControllerSearchAll>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchAll>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchControllerSearchAllQueryKey(query, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchControllerSearchAll>>
  > = ({ signal }) =>
    searchControllerSearchAll(query, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!query,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchControllerSearchAll>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchControllerSearchAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchControllerSearchAll>>
>;
export type SearchControllerSearchAllQueryError = AxiosError<unknown>;

export const useSearchControllerSearchAll = <
  TData = Awaited<ReturnType<typeof searchControllerSearchAll>>,
  TError = AxiosError<unknown>,
>(
  query: string,
  params?: SearchControllerSearchAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof searchControllerSearchAll>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchControllerSearchAllQueryOptions(
    query,
    params,
    options,
  );

  const _query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  _query.queryKey = queryOptions.queryKey;

  return _query;
};

export const productControllerGetProduct = (
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ProductDetailsDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/product/${id}/${kind}`,
    options,
  );
};

export const getProductControllerGetProductQueryKey = (
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
) => {
  return [
    `https://staging.workspacewarden.com/api/product/${id}/${kind}`,
  ] as const;
};

export const getProductControllerGetProductQueryOptions = <
  TData = Awaited<ReturnType<typeof productControllerGetProduct>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof productControllerGetProduct>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getProductControllerGetProductQueryKey(id, kind);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof productControllerGetProduct>>
  > = ({ signal }) =>
    productControllerGetProduct(id, kind, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!(id && kind),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof productControllerGetProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ProductControllerGetProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof productControllerGetProduct>>
>;
export type ProductControllerGetProductQueryError = AxiosError<unknown>;

export const useProductControllerGetProduct = <
  TData = Awaited<ReturnType<typeof productControllerGetProduct>>,
  TError = AxiosError<unknown>,
>(
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof productControllerGetProduct>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getProductControllerGetProductQueryOptions(
    id,
    kind,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const productControllerAddMember = (
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  addMemberDto: AddMemberDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/product/${id}/${kind}/member`,
    addMemberDto,
    options,
  );
};

export const getProductControllerAddMemberMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerAddMember>>,
    TError,
    { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: AddMemberDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof productControllerAddMember>>,
  TError,
  { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: AddMemberDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof productControllerAddMember>>,
    { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: AddMemberDto }
  > = (props) => {
    const { id, kind, data } = props ?? {};

    return productControllerAddMember(id, kind, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProductControllerAddMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof productControllerAddMember>>
>;
export type ProductControllerAddMemberMutationBody = AddMemberDto;
export type ProductControllerAddMemberMutationError = AxiosError<unknown>;

export const useProductControllerAddMember = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerAddMember>>,
    TError,
    { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: AddMemberDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof productControllerAddMember>>,
  TError,
  { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: AddMemberDto },
  TContext
> => {
  const mutationOptions = getProductControllerAddMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const productControllerUpdateMember = (
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  updateMemberRoleDto: UpdateMemberRoleDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `https://staging.workspacewarden.com/api/product/${id}/${kind}/member`,
    updateMemberRoleDto,
    options,
  );
};

export const getProductControllerUpdateMemberMutationOptions = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerUpdateMember>>,
    TError,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: UpdateMemberRoleDto;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof productControllerUpdateMember>>,
  TError,
  {
    id: string;
    kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
    data: UpdateMemberRoleDto;
  },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof productControllerUpdateMember>>,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: UpdateMemberRoleDto;
    }
  > = (props) => {
    const { id, kind, data } = props ?? {};

    return productControllerUpdateMember(id, kind, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProductControllerUpdateMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof productControllerUpdateMember>>
>;
export type ProductControllerUpdateMemberMutationBody = UpdateMemberRoleDto;
export type ProductControllerUpdateMemberMutationError = AxiosError<void>;

export const useProductControllerUpdateMember = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerUpdateMember>>,
    TError,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: UpdateMemberRoleDto;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof productControllerUpdateMember>>,
  TError,
  {
    id: string;
    kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
    data: UpdateMemberRoleDto;
  },
  TContext
> => {
  const mutationOptions =
    getProductControllerUpdateMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const productControllerDeleteMember = (
  id: string,
  kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX',
  removeMemberDto: RemoveMemberDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/product/${id}/${kind}/member`,
    { data: removeMemberDto, ...options },
  );
};

export const getProductControllerDeleteMemberMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerDeleteMember>>,
    TError,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: RemoveMemberDto;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof productControllerDeleteMember>>,
  TError,
  { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: RemoveMemberDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof productControllerDeleteMember>>,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: RemoveMemberDto;
    }
  > = (props) => {
    const { id, kind, data } = props ?? {};

    return productControllerDeleteMember(id, kind, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProductControllerDeleteMemberMutationResult = NonNullable<
  Awaited<ReturnType<typeof productControllerDeleteMember>>
>;
export type ProductControllerDeleteMemberMutationBody = RemoveMemberDto;
export type ProductControllerDeleteMemberMutationError = AxiosError<unknown>;

export const useProductControllerDeleteMember = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof productControllerDeleteMember>>,
    TError,
    {
      id: string;
      kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX';
      data: RemoveMemberDto;
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof productControllerDeleteMember>>,
  TError,
  { id: string; kind: 'DRIVE' | 'CALENDAR' | 'MAILBOX'; data: RemoveMemberDto },
  TContext
> => {
  const mutationOptions =
    getProductControllerDeleteMemberMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerCreate = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/tenants/create`,
    undefined,
    options,
  );
};

export const getTenantControllerCreateMutationOptions = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerCreate>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerCreate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerCreate>>,
    void
  > = () => {
    return tenantControllerCreate(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerCreate>>
>;

export type TenantControllerCreateMutationError = AxiosError<void>;

export const useTenantControllerCreate = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerCreate>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerCreate>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getTenantControllerCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerGetOrgUnits = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<OrgUnitsDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/tenants/orgunit`,
    options,
  );
};

export const getTenantControllerGetOrgUnitsQueryKey = () => {
  return [`https://staging.workspacewarden.com/api/tenants/orgunit`] as const;
};

export const getTenantControllerGetOrgUnitsQueryOptions = <
  TData = Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTenantControllerGetOrgUnitsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>
  > = ({ signal }) => tenantControllerGetOrgUnits({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TenantControllerGetOrgUnitsQueryResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>
>;
export type TenantControllerGetOrgUnitsQueryError = AxiosError<unknown>;

export const useTenantControllerGetOrgUnits = <
  TData = Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetOrgUnits>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTenantControllerGetOrgUnitsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tenantControllerSetOrgUnits = (
  orgUnitsDto: OrgUnitsDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/tenants/orgunit`,
    orgUnitsDto,
    options,
  );
};

export const getTenantControllerSetOrgUnitsMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>,
    TError,
    { data: OrgUnitsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>,
  TError,
  { data: OrgUnitsDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>,
    { data: OrgUnitsDto }
  > = (props) => {
    const { data } = props ?? {};

    return tenantControllerSetOrgUnits(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerSetOrgUnitsMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>
>;
export type TenantControllerSetOrgUnitsMutationBody = OrgUnitsDto;
export type TenantControllerSetOrgUnitsMutationError = AxiosError<unknown>;

export const useTenantControllerSetOrgUnits = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>,
    TError,
    { data: OrgUnitsDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerSetOrgUnits>>,
  TError,
  { data: OrgUnitsDto },
  TContext
> => {
  const mutationOptions =
    getTenantControllerSetOrgUnitsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerGetImpersonator = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ImpersonatorDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/tenants/impersonator`,
    options,
  );
};

export const getTenantControllerGetImpersonatorQueryKey = () => {
  return [
    `https://staging.workspacewarden.com/api/tenants/impersonator`,
  ] as const;
};

export const getTenantControllerGetImpersonatorQueryOptions = <
  TData = Awaited<ReturnType<typeof tenantControllerGetImpersonator>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetImpersonator>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTenantControllerGetImpersonatorQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tenantControllerGetImpersonator>>
  > = ({ signal }) =>
    tenantControllerGetImpersonator({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tenantControllerGetImpersonator>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TenantControllerGetImpersonatorQueryResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerGetImpersonator>>
>;
export type TenantControllerGetImpersonatorQueryError = AxiosError<unknown>;

export const useTenantControllerGetImpersonator = <
  TData = Awaited<ReturnType<typeof tenantControllerGetImpersonator>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetImpersonator>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTenantControllerGetImpersonatorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tenantControllerSetImpersonator = (
  impersonatorDto: ImpersonatorDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/tenants/impersonator`,
    impersonatorDto,
    options,
  );
};

export const getTenantControllerSetImpersonatorMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetImpersonator>>,
    TError,
    { data: ImpersonatorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerSetImpersonator>>,
  TError,
  { data: ImpersonatorDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerSetImpersonator>>,
    { data: ImpersonatorDto }
  > = (props) => {
    const { data } = props ?? {};

    return tenantControllerSetImpersonator(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerSetImpersonatorMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerSetImpersonator>>
>;
export type TenantControllerSetImpersonatorMutationBody = ImpersonatorDto;
export type TenantControllerSetImpersonatorMutationError = AxiosError<unknown>;

export const useTenantControllerSetImpersonator = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetImpersonator>>,
    TError,
    { data: ImpersonatorDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerSetImpersonator>>,
  TError,
  { data: ImpersonatorDto },
  TContext
> => {
  const mutationOptions =
    getTenantControllerSetImpersonatorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerGetAuthorizedUsers = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetAuthorizedUsersDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/tenants/authorized`,
    options,
  );
};

export const getTenantControllerGetAuthorizedUsersQueryKey = () => {
  return [
    `https://staging.workspacewarden.com/api/tenants/authorized`,
  ] as const;
};

export const getTenantControllerGetAuthorizedUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTenantControllerGetAuthorizedUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>
  > = ({ signal }) =>
    tenantControllerGetAuthorizedUsers({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TenantControllerGetAuthorizedUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>
>;
export type TenantControllerGetAuthorizedUsersQueryError = AxiosError<unknown>;

export const useTenantControllerGetAuthorizedUsers = <
  TData = Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetAuthorizedUsers>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getTenantControllerGetAuthorizedUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tenantControllerAddAuthorizedUser = (
  addAuthorizedUserDto: AddAuthorizedUserDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.patch(
    `https://staging.workspacewarden.com/api/tenants/authorized`,
    addAuthorizedUserDto,
    options,
  );
};

export const getTenantControllerAddAuthorizedUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>,
    TError,
    { data: AddAuthorizedUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>,
  TError,
  { data: AddAuthorizedUserDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>,
    { data: AddAuthorizedUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return tenantControllerAddAuthorizedUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerAddAuthorizedUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>
>;
export type TenantControllerAddAuthorizedUserMutationBody =
  AddAuthorizedUserDto;
export type TenantControllerAddAuthorizedUserMutationError =
  AxiosError<unknown>;

export const useTenantControllerAddAuthorizedUser = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>,
    TError,
    { data: AddAuthorizedUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerAddAuthorizedUser>>,
  TError,
  { data: AddAuthorizedUserDto },
  TContext
> => {
  const mutationOptions =
    getTenantControllerAddAuthorizedUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerRemoveAuthorizedUser = (
  removeAuthorizedUserDto: RemoveAuthorizedUserDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/tenants/authorized`,
    { data: removeAuthorizedUserDto, ...options },
  );
};

export const getTenantControllerRemoveAuthorizedUserMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>,
    TError,
    { data: RemoveAuthorizedUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>,
  TError,
  { data: RemoveAuthorizedUserDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>,
    { data: RemoveAuthorizedUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return tenantControllerRemoveAuthorizedUser(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerRemoveAuthorizedUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>
>;
export type TenantControllerRemoveAuthorizedUserMutationBody =
  RemoveAuthorizedUserDto;
export type TenantControllerRemoveAuthorizedUserMutationError =
  AxiosError<unknown>;

export const useTenantControllerRemoveAuthorizedUser = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>,
    TError,
    { data: RemoveAuthorizedUserDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerRemoveAuthorizedUser>>,
  TError,
  { data: RemoveAuthorizedUserDto },
  TContext
> => {
  const mutationOptions =
    getTenantControllerRemoveAuthorizedUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const tenantControllerGetBillingInfo = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetBillingInfoDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/tenants/billing-info`,
    options,
  );
};

export const getTenantControllerGetBillingInfoQueryKey = () => {
  return [
    `https://staging.workspacewarden.com/api/tenants/billing-info`,
  ] as const;
};

export const getTenantControllerGetBillingInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>,
  TError = AxiosError<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTenantControllerGetBillingInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>
  > = ({ signal }) =>
    tenantControllerGetBillingInfo({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TenantControllerGetBillingInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>
>;
export type TenantControllerGetBillingInfoQueryError = AxiosError<void>;

export const useTenantControllerGetBillingInfo = <
  TData = Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>,
  TError = AxiosError<void>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof tenantControllerGetBillingInfo>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTenantControllerGetBillingInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const tenantControllerSetBillingInfo = (
  setBillingInfoDto: SetBillingInfoDto,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.put(
    `https://staging.workspacewarden.com/api/tenants/billing-info`,
    setBillingInfoDto,
    options,
  );
};

export const getTenantControllerSetBillingInfoMutationOptions = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>,
    TError,
    { data: SetBillingInfoDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>,
  TError,
  { data: SetBillingInfoDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>,
    { data: SetBillingInfoDto }
  > = (props) => {
    const { data } = props ?? {};

    return tenantControllerSetBillingInfo(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TenantControllerSetBillingInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>
>;
export type TenantControllerSetBillingInfoMutationBody = SetBillingInfoDto;
export type TenantControllerSetBillingInfoMutationError = AxiosError<void>;

export const useTenantControllerSetBillingInfo = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>,
    TError,
    { data: SetBillingInfoDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof tenantControllerSetBillingInfo>>,
  TError,
  { data: SetBillingInfoDto },
  TContext
> => {
  const mutationOptions =
    getTenantControllerSetBillingInfoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authControllerLogin = (
  params: AuthControllerLoginParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<LoginInfoDto>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/auth/login`,
    undefined,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAuthControllerLoginMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogin>>,
    TError,
    { params: AuthControllerLoginParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerLogin>>,
  TError,
  { params: AuthControllerLoginParams },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerLogin>>,
    { params: AuthControllerLoginParams }
  > = (props) => {
    const { params } = props ?? {};

    return authControllerLogin(params, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerLogin>>
>;

export type AuthControllerLoginMutationError = AxiosError<unknown>;

export const useAuthControllerLogin = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogin>>,
    TError,
    { params: AuthControllerLoginParams },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerLogin>>,
  TError,
  { params: AuthControllerLoginParams },
  TContext
> => {
  const mutationOptions = getAuthControllerLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authControllerLogout = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(
    `https://staging.workspacewarden.com/api/auth/logout`,
    options,
  );
};

export const getAuthControllerLogoutMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogout>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authControllerLogout>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authControllerLogout>>,
    void
  > = () => {
    return authControllerLogout(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthControllerLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof authControllerLogout>>
>;

export type AuthControllerLogoutMutationError = AxiosError<unknown>;

export const useAuthControllerLogout = <
  TError = AxiosError<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authControllerLogout>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof authControllerLogout>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAuthControllerLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};

export const authControllerGetMe = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<LoginInfoDto>> => {
  return axios.get(`https://staging.workspacewarden.com/api/auth/me`, options);
};

export const getAuthControllerGetMeQueryKey = () => {
  return [`https://staging.workspacewarden.com/api/auth/me`] as const;
};

export const getAuthControllerGetMeQueryOptions = <
  TData = Awaited<ReturnType<typeof authControllerGetMe>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authControllerGetMe>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthControllerGetMeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authControllerGetMe>>
  > = ({ signal }) => authControllerGetMe({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authControllerGetMe>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthControllerGetMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof authControllerGetMe>>
>;
export type AuthControllerGetMeQueryError = AxiosError<unknown>;

export const useAuthControllerGetMe = <
  TData = Awaited<ReturnType<typeof authControllerGetMe>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof authControllerGetMe>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthControllerGetMeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const syncControllerGetSyncInfo = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<SyncInfoDto>> => {
  return axios.get(
    `https://staging.workspacewarden.com/api/sync/info`,
    options,
  );
};

export const getSyncControllerGetSyncInfoQueryKey = () => {
  return [`https://staging.workspacewarden.com/api/sync/info`] as const;
};

export const getSyncControllerGetSyncInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof syncControllerGetSyncInfo>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof syncControllerGetSyncInfo>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSyncControllerGetSyncInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof syncControllerGetSyncInfo>>
  > = ({ signal }) => syncControllerGetSyncInfo({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof syncControllerGetSyncInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SyncControllerGetSyncInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof syncControllerGetSyncInfo>>
>;
export type SyncControllerGetSyncInfoQueryError = AxiosError<unknown>;

export const useSyncControllerGetSyncInfo = <
  TData = Awaited<ReturnType<typeof syncControllerGetSyncInfo>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof syncControllerGetSyncInfo>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSyncControllerGetSyncInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const syncControllerStartSync = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `https://staging.workspacewarden.com/api/sync/start`,
    undefined,
    options,
  );
};

export const getSyncControllerStartSyncMutationOptions = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncControllerStartSync>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof syncControllerStartSync>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof syncControllerStartSync>>,
    void
  > = () => {
    return syncControllerStartSync(axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SyncControllerStartSyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof syncControllerStartSync>>
>;

export type SyncControllerStartSyncMutationError = AxiosError<void>;

export const useSyncControllerStartSync = <
  TError = AxiosError<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof syncControllerStartSync>>,
    TError,
    void,
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof syncControllerStartSync>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSyncControllerStartSyncMutationOptions(options);

  return useMutation(mutationOptions);
};
