import { Box, Flex, HStack, useMediaQuery } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import {
  Layout,
  LoadingPlaceholder,
  PageContainer,
  ChecklistsList,
  DetailViewContainer,
  ChecklistDetails,
  ChecklistCreationModal,
  ChecklistUploadModal,
} from '../modules';
import { ChecklistDto } from '@models';
import {
  useChecklistControllerFindAll,
  useChecklistControllerGetChecklist,
} from '@hooks';

export function ChecklistsPage() {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isDetailsClosed, setIsDetailsClosed] = useState(false);
  const [selectedChecklist, setSelectedChecklist] =
    useState<ChecklistDto | null>(null);
  const urlMatch = useMatch('/checklists/:checklistId');

  const {
    data: checklists,
    isPending: isLoadingChecklists,
    refetch: refetchChecklists,
  } = useChecklistControllerFindAll();

  const {
    data: checklist,
    refetch: refetchChecklist,
    isLoading: isLoadingChecklist,
  } = useChecklistControllerGetChecklist(
    // TODO: Fix
    // @ts-expect-error possibly undefined, but should not matter due to enabled property
    selectedChecklist?.id,
    {
      query: {
        enabled: !!selectedChecklist,
      },
    },
  );

  const setSelectedChecklistFromId = useCallback(
    (id: string) => {
      if (!checklists) return;
      const checklist: ChecklistDto | undefined = checklists.data.find(
        (c: ChecklistDto) => c.id === id,
      );
      if (!checklist) return;
      setSelectedChecklist(checklist);
    },
    [checklists],
  );

  useEffect(() => {
    if (!urlMatch && !isMobile) {
      if (checklists && checklists.data && checklists.data?.length > 0) {
        navigate(`/checklists/${checklists.data[0].id}`);
        setSelectedChecklist(checklists.data[0]);
      }
    } else if (urlMatch) {
      const { checklistId: checklistIdFromMatch } = urlMatch.params;
      if (!checklistIdFromMatch) return;
      setSelectedChecklistFromId(checklistIdFromMatch);
    }

    if (selectedChecklist) setIsDetailsClosed(false);
    if (!urlMatch && isMobile) {
      setIsDetailsClosed(true);
      setSelectedChecklist(null);
    }
  }, [urlMatch, checklists, setSelectedChecklistFromId, isMobile]);

  const navigate = useNavigate();

  function handleChecklistSelection(checklist: ChecklistDto) {
    setSelectedChecklist(checklist);
    setIsDetailsClosed(false);
    navigate(checklist.id, { state: { checklist } });
  }

  const closeDetailsHandler = () => {
    setIsDetailsClosed(true);
    if (isMobile) {
      setSelectedChecklist(null);
      navigate('/checklists');
    }
  };

  return (
    <Layout title={t('checklists:page_title')}>
      <PageContainer
        isDetailsClosed={isDetailsClosed}
        list={
          <Flex
            p={{ base: 4, md: 4, lg: 6 }}
            h="full"
            w="full"
            flexDir="column"
            overflow="hidden"
            gap={{ base: 3, md: 4, lg: 6 }}
          >
            <HStack w="full" gap={4} justifyContent="flex-end">
              <ChecklistUploadModal
                selectionHandler={handleChecklistSelection}
                refetchChecklists={refetchChecklists}
              />
              <ChecklistCreationModal
                selectionHandler={handleChecklistSelection}
                refetchChecklists={refetchChecklists}
              />
            </HStack>
            <Flex h="full" overflow="hidden">
              <Flex
                p={0}
                h="full"
                flex={1}
                display="flex"
                flexDir="column"
                justifyContent="space-between"
                overflow="auto"
              >
                {isLoadingChecklists ? (
                  <LoadingPlaceholder
                    text={t('checklists:loading_checklists')}
                  />
                ) : (
                  <ChecklistsList
                    checklists={checklists!.data}
                    selected={selectedChecklist}
                    selectionHandler={handleChecklistSelection}
                    refetchChecklists={refetchChecklists}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        }
        details={
          <Box
            w="full"
            overflow="hidden"
            bgColor="brand.white"
            borderLeftRadius="xl"
          >
            {selectedChecklist && (
              <DetailViewContainer
                handleIsDetailsClosed={closeDetailsHandler}
                label={t('checklists:kind_checklist')}
                description={selectedChecklist.name}
                children={
                  checklist && !isLoadingChecklist ? (
                    <ChecklistDetails
                      checklist={checklist.data}
                      refetch={refetchChecklist}
                    />
                  ) : (
                    <LoadingPlaceholder
                      text={t('checklists:loading_checklist')}
                    />
                  )
                }
              />
            )}
          </Box>
        }
      />
    </Layout>
  );
}
