import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '../../modules';
import {
  getAuthControllerGetMeQueryKey,
  useAuthControllerGetMe,
  useTenantControllerCreate,
} from '@hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function MarketPlaceInstallationPage() {
  const { t } = useTranslation();
  const [isInstalled, setIsInstalled] = useState<boolean | null>(null);
  const [hasStartedTrial, setHasStartedTrial] = useState<boolean | null>(null);

  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data: me } = useAuthControllerGetMe({
    query: {
      refetchInterval,
    },
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: createTenant } = useTenantControllerCreate({
    mutation: {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: getAuthControllerGetMeQueryKey(),
        });
      },
    },
  });

  const onStartTrial = async () => {
    setRefetchInterval(2000);
    await createTenant();
    setHasStartedTrial(true);
  };

  useEffect(() => {
    if (me?.data.setupCompleted) {
      setRefetchInterval(false);
      if (localStorage.getItem('redirect-to-subscriptions')) {
        const redirect = localStorage.getItem('redirect-to-subscriptions');
        if (!redirect) return;
        navigate(redirect);
        localStorage.removeItem('redirect-to-subscriptions');
      } else {
        navigate('/products');
      }
    }
  }, [me]);

  return (
    <Flex
      w="full"
      h="full"
      zIndex={0}
      bgImage="url(/background.png)"
      bgSize="cover"
      bgPosition="center"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      flexDir={{ base: 'column', lg: 'row' }}
      p={{ base: 4, lg: 12 }}
    >
      {!isInstalled && !hasStartedTrial && (
        <InfoCard
          type="install"
          subtitle={t('subscriptions:info_marketplace_subtitle')}
          title={t('subscriptions:info_marketplace_title')}
          message={t('subscriptions:info_marketplace_message')}
          linkLabel={t('subscriptions:info_marketplace_link')}
          target="_blank"
          // TODO: add link to Marketplace
          link="#"
          handleOnClick={() => setIsInstalled(true)}
        />
      )}
      {isInstalled && !hasStartedTrial && (
        <InfoCard
          type="info"
          subtitle={t('subscriptions:info_marketplace_installed_subtitle')}
          title={t('subscriptions:info_marketplace_installed_title')}
          message={t('subscriptions:info_marketplace_installed_message')}
          linkLabel={t('subscriptions:info_marketplace_installed_link')}
          handleOnClick={onStartTrial}
        />
      )}
      {isInstalled && hasStartedTrial && (
        <InfoCard
          type={'sync'}
          subtitle={t('subscriptions:info_sync_subtitle')}
          title={t('subscriptions:info_sync_title')}
          message={t('subscriptions:info_sync_message')}
        />
      )}
    </Flex>
  );
}
