import {
  Box,
  Center,
  HStack,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  useAuthControllerGetMe,
  useProductControllerGetProduct,
  useSearchControllerGetAllProductsInfinite,
} from '@hooks';

import {
  Layout,
  LoadingPlaceholder,
  PageContainer,
  CustomTabs,
  CustomTabList,
  CustomTab,
  CustomTabPanels,
  CustomTabPanel,
  ProductsList,
  DetailViewContainer,
  ProductDetails,
} from '../modules';
import {
  ProductSearchResultDto,
  SearchControllerGetAllProductsKind,
} from '@models';
import { SelectedProduct } from '../modules/products/models';

type Kind = SearchControllerGetAllProductsKind;

export function ProductsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filter, setFilter] = useState<Kind | null>('DRIVE');
  const [tabIndex, setTabIndex] = useState(0);
  const [isDetailsClosed, setIsDetailsClosed] = useState(true);

  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProduct | null>(null);

  const urlMatch = useMatch('/products/:kind/:productId');

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const {
    data: products,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useSearchControllerGetAllProductsInfinite(
    { kind: filter ?? undefined },
    {
      query: {
        getNextPageParam: (last, all) => {
          const nextPage = all.length + 1;
          return last.data.length !== 0 ? nextPage : undefined;
        },
      },
    },
  );

  const { data: product, isLoading: isLoadingProduct, refetch } =
    // @ts-expect-error undefined
    useProductControllerGetProduct(selectedProduct?.id, selectedProduct?.kind);

  useEffect(() => {
    if (selectedProduct) return;
    if (!urlMatch) return;
    const { productId: productIdFromMatch, kind: kindFromMatch } =
      urlMatch.params;
    if (!productIdFromMatch || !kindFromMatch) return;
    setSelectedProduct({
      id: productIdFromMatch,
      kind: kindFromMatch.toUpperCase() as Kind,
    });
  }, [urlMatch]);

  function handleProductSelection(product: ProductSearchResultDto) {
    setSelectedProduct({
      id: product.id,
      kind: product.kind,
      name: product.name,
    });
    setIsDetailsClosed(false);
    navigate(`/products/${product.kind?.toLowerCase()}/${product.id}`, {
      state: { product },
    });
  }

  useEffect(() => {
    const productResults = products?.pages.flatMap((page) => page.data);

    if (!urlMatch && productResults && !selectedProduct && !isMobile) {
      const first = productResults[0];
      setSelectedProduct(first);
      navigate(`/products/${first.kind?.toLowerCase()}/${first.id}`, {
        state: { product: first },
      });
    }

    if (selectedProduct) setIsDetailsClosed(false);
    if (!urlMatch && isMobile) {
      setIsDetailsClosed(true);
      setSelectedProduct(null);
    }
  }, [urlMatch, selectedProduct, products, isMobile]);

  const closeDetailsHandler = () => {
    setIsDetailsClosed(true);
    if (isMobile) {
      setSelectedProduct(null);
      navigate('/products');
    }
  };

  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { data: me } = useAuthControllerGetMe({
    query: {
      refetchInterval,
    },
  });

  useEffect(() => {
    if (me?.data.setupCompleted) {
      setRefetchInterval(false);
    }
    setRefetchInterval(2000);
  }, [me]);

  return (
    <Layout title={t('products:page_title')}>
      {!me?.data.setupCompleted && (
        <Center
          textAlign="center"
          w="full"
          display="flex"
          flexDir="column"
          gap={10}
          p={12}
        >
          <VStack w="full">
            <Text fontWeight="bold" fontSize={{ base: 'md', lg: 'lg' }}>
              {t('sync:title_synchronizing_products')}
            </Text>
            <Text> {t('sync:subtitle_synchronizing_products')}</Text>
          </VStack>
          <VStack w="full">
            <Text> {t('sync:choice_explore_application')}</Text>
            <HStack>
              <Box bg="gray.300" h="1px" w="120px" />
              <Text>{t('sync:choice_or')}</Text>
              <Box bg="gray.300" h="1px" w="120px" />
            </HStack>
            <Text>{t('sync:choice_close_tab')}</Text>
          </VStack>
        </Center>
      )}
      {me?.data.setupCompleted && (
        <PageContainer
          isDetailsClosed={isDetailsClosed}
          list={
            <CustomTabs tabIndex={tabIndex} tabsChangeHandler={handleTabChange}>
              <CustomTabList>
                <CustomTab
                  name={t('tabs:tab_all')}
                  onClickHandler={() => setFilter(null)}
                />
                <CustomTab
                  name={t('tabs:tab_drives')}
                  onClickHandler={() => setFilter('DRIVE')}
                />
                <CustomTab
                  name={t('tabs:tab_mailboxes')}
                  onClickHandler={() => setFilter('MAILBOX')}
                />
                <CustomTab
                  name={t('tabs:tab_calendars')}
                  onClickHandler={() => setFilter('CALENDAR')}
                />
              </CustomTabList>
              <CustomTabPanels>
                {new Array(4).fill(
                  <CustomTabPanel key={filter}>
                    {isLoading ? (
                      <LoadingPlaceholder
                        text={t('products:loading_products')}
                      />
                    ) : (
                      <ProductsList
                        products={
                          products?.pages.flatMap((page) => page.data) || []
                        }
                        selected={selectedProduct}
                        selectionHandler={handleProductSelection}
                        handleFetchNextPage={fetchNextPage}
                        isFetching={isFetchingNextPage}
                        hasNextPage={hasNextPage}
                      />
                    )}
                  </CustomTabPanel>,
                )}
              </CustomTabPanels>
            </CustomTabs>
          }
          details={
            <Box
              w="full"
              overflow="hidden"
              bgColor="brand.white"
              borderLeftRadius="xl"
            >
              {selectedProduct && (
                <DetailViewContainer
                  handleIsDetailsClosed={closeDetailsHandler}
                  label={t(
                    `products:title_details.${selectedProduct.kind.toLowerCase()}`,
                  )}
                  description={selectedProduct.name ?? product?.data.name}
                  children={
                    product && !isLoadingProduct ? (
                      <ProductDetails
                        product={product.data}
                        refetch={refetch}
                      />
                    ) : (
                      <LoadingPlaceholder
                        text={t('products:loading_product')}
                      />
                    )
                  }
                />
              )}
            </Box>
          }
        />
      )}
    </Layout>
  );
}
